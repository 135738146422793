@font-face {
    font-family: 'CentraNube';
    src:  url('../assets/fonts/centranube-book.woff2') format('woff2'),
        url('../assets/fonts/centranube-book.woff') format('woff'),
        url('../assets/fonts/centranube-book.ttf') format('truetype'),
        url('../assets/fonts/centranube-book.eot') format('eot');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'CentraNube';
    src:  url('../assets/fonts/centranube-medium.woff2') format('woff2'),
        url('../assets/fonts/centranube-medium.woff') format('woff'),
        url('../assets/fonts/centranube-medium.ttf') format('truetype'),
        url('../assets/fonts/centranube-medium.eot') format('eot');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DM Serif Display';
    src:  url('../assets/fonts/serifdisplay-Italic.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@media screen {
    .onlyPrint {
        display: none!important;
    }
}

@media print
{
    .no-print, .no-print *
    {
        display: none !important;
    }
}